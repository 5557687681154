<template>
	<div>
		<div class="jumbotron jumbotron-fluid bg-red text-white mt-5 mb-0 text-left">
			<div class="container">
				<div class="row">
					<div class="col">
						<p>
							<a class="text-white" href="/uploads/2019_Privacyverklaring-Simone-Busscher.pdf" target="_blank">Privacy</a>
						</p>
						<p>
							<a class="text-white" href="/disclaimer">Disclaimer</a>
						</p>
						<p>Sitemap</p>
						<p>KVK-nummer: 08197895</p>
					</div>
					<div class="col offset-md-6">
						<p>
							<span class="font-weight-bold">SIMONE BUSSCHER</span>
							<br />Bezoekadres:
							<br />Vriezenveenseweg 2
							<br />7678 VC Geesteren (ov)
						</p>
						<p class="pt-0">
							Postadres:
							<br />Oude Lutkeberg 6
							<br />7678 AK Geesteren (ov)
						</p>
						<p class="pt-2">
							06 22 87 15 87
							<br />
							<a class="text-white" href="mailto:info@simonebusscher.nl">info@simonebusscher.nl</a>
						</p>
					</div>
				</div>
			</div>
		</div>
		<div class="container mt-3 mb-1">
			<div class="row">
				<div class="col">
					<p>©2019, Simone Busscher - alle rechten voorbehouden</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {};
</script>

<style scoped>
p {
	font-size: 12px;
}
</style>