<template>
	<div :style="{ '--aspect-ratio' : + aspect}" class="my-1 hovered">
		<div class="aspect-ratio-box-inside">
			<div class="h-100 w-100 text-white text-center valign-parent" :class="classObject" :style="styleObject">
				<div class="valign-child">
					<p class="btn-title">{{title}}</p>
					<p class="btn-text" v-if="content!=''">{{content}}</p>
					<br />
					<Arrow width="40" :link="link"></Arrow>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Arrow from '@/components/Arrow.vue';

export default {
	data() {
		return {
			classObject: '',
			styleObject: ''
		};
	},
	components: {
		Arrow
	},
	props: {
		title: { type: String },
		content: { type: String },
		bgColor: { type: String },
		image: { type: String, default: '/uploads/robin2.jpg' },
		link: { type: String },
		aspect: { type: String, default: '0.8' }
	},
	created: function() {
		// console.clear();

		if (this.bgColor != '') {
			this.classObject = this.bgColor;
		} else {
			this.classObject = 'div-bg-img';
			this.styleObject = { backgroundImage: 'url(' + this.image + ')' };
		}

		// console.log(this.image);
		// console.log(this.styleObject);
	}
};
</script>

<style scoped>
.hovered {
	overflow: hidden;
}

.div-bg-img {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	height: 100%;
	width: 100%;
}

.btn-text {
	margin: 0;
	padding: 0 10px;
	color: white;
}

.btn-title {
	text-transform: uppercase;
	font-size: 20px;
}

.valign-parent {
	display: table;
}
.valign-child {
	display: table-cell;
	vertical-align: middle;
}
</style>
