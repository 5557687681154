<template>
	<div class="text-center">
		<div class="jumbotron jumbotron-fluid pt-1 pb-0">
			<div class="row mr-0">
				<!-- left column -->
				<div class="col bg-pink">
					<div class="container has-width-50 float-right">
						<div class="row">
							<div class="col">
								<div class="valign-parent">
									<div class="valign-child">
										<h1 class="banner-text mt-2">THUIS</h1>
									</div>
									<div class="valign-child pl-2">
										<Arrow v-if="!showText" link="/thuis"></Arrow>
									</div>
								</div>
								<div v-if="showText">
									<p
										class="banner-text pr-large"
									>Thuis ligt je basis. Het is je ‘nest’ waar je tot rust komt. Maar wat doe je als die basis even verstoord is? Als jouw nest je even geen rust en ontspanning biedt?</p>
									<div class="valign-parent">
										<div class="valign-child">
											<span class="banner-text">Kijk wat ik doe voor ouders en/of kinderen</span>
										</div>
										<div class="valign-child">
											<Arrow link="/thuis"></Arrow>
										</div>
									</div>
									<br />
								</div>
							</div>
						</div>
					</div>
				</div>

				<!-- right column -->
				<div class="col bg-red">
					<div class="container has-width-50 float-md-left">
						<div class="row">
							<div class="col pl-large">
								<div class="valign-parent">
									<div class="valign-child">
										<h1 class="banner-text mt-2">OP SCHOOL</h1>
									</div>
									<div class="valign-child pl-2">
										<Arrow v-if="!showText" link="/school"></Arrow>
									</div>
								</div>
								<div v-if="showText">
									<p
										class="banner-text"
									>Iedereen is eigen, uniek. Dat is mooi; je mag zijn wie je bent. En blijven wie je bent. Met alles wat erbij hoort. Een veilige leef- én leeromgeving is daarvoor de basis.</p>
									<div class="valign-parent">
										<div class="valign-child">
											<span class="banner-text">Kijk wat ik doe voor scholen en/of leerlingen</span>
										</div>
										<div class="valign-child">
											<Arrow link="/school"></Arrow>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- logo button in the middle -->
		<router-link to="/simone" class="d-none d-sm-none d-md-block">
			<img src="@/assets/logo-knop.png" class="logo-knop-small float-right" v-bind:class="{'logo-knop-large': showText}" />
		</router-link>
	</div>
</template>

<script>
import Arrow from '@/components/Arrow.vue';

export default {
	data() {
		return {
			styleObject: {
				top: '362px',
				marginLeft: '-50px',
				maxWidth: '86px'
			}
		};
	},
	components: {
		Arrow
	},
	props: {
		showText: Boolean
	}
};
</script>


<style scoped>
.banner-text {
	color: white;
	text-align: left;
}

.logo-knop-small {
	position: absolute;
	top: 373px;
	margin-left: -50px;
	max-width: 86px;
	z-index: 9;
}

.logo-knop-large {
	position: absolute;
	top: 373px;
	margin-left: -117px;
	max-width: 220px;
	z-index: 9;
}

.has-width-50 {
	max-width: 570px !important;
}

@media (min-width: 768px) {
	.pr-large {
		padding-right: 90px;
	}
}

@media (min-width: 768px) {
	.pl-large {
		padding-left: 120px;
	}
}

.valign-parent {
	display: table;
}
.valign-child {
	display: table-cell;
	vertical-align: middle;
}
</style>
